import { client, errorValidation } from "./service";

export const getAdminList = async function () {
    try {
        let response = await client.get(`/admins`);
        console.log('here response', response)
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data };
    } catch (e) {
        return errorValidation(e)
    }
}

export const createAdmin = async function ({
    name, username, password, isActive
}) {
    try {
        let response = await client.post(`/create-admin`, {
            name, username, password, isActive
        });
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data };
    } catch (e) {
        // return { error: e.response.data.errorCode }
        return errorValidation(e)
    }
}