// assets
import { LoginOutlined, ProfileOutlined, HomeOutlined, CalendarOutlined, PercentageOutlined } from '@ant-design/icons';

// icons
const icons = {
  LoginOutlined,
  ProfileOutlined,
  HomeOutlined,
  CalendarOutlined,
  PercentageOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
  id: 'places',
  title: '',
  type: 'group',
  children: [
    {
      id: 'places',
      title: 'Places',
      type: 'item',
      url: '/places',
      icon: icons.HomeOutlined,
      breadcrumbs: true
      // target: true
    },
    {
      id: 'orders',
      title: 'Orders',
      type: 'item',
      url: '/order',
      icon: icons.CalendarOutlined,
      breadcrumbs: true
      // target: true
    },
    {
      id: 'coupons',
      title: 'Coupons',
      type: 'item',
      url: '/coupons',
      icon: icons.PercentageOutlined,
      breadcrumbs: true
      // target: true
    }
  ]
};

export default pages;
