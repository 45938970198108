import React, { useEffect, useState } from "react";
import { Button, Card, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import LBVTable from "components/_lbvcomponents/LBVTable";
import { LBVLabel } from "components/_lbvcomponents/LBVLabel";
import { getAdminList } from "services/adminService";

function Admins () {
    const navigate = useNavigate()

    const [ admins, setAdmins ] = useState([])
    const [ loading, setLoading ] = useState(false)

    useEffect(() => {
        loadAdminList()
    }, [])

    const loadAdminList = async () => {
        let { data, error } = await getAdminList()
        if (error) { console.log(error) }
        if (data) { setAdmins(data) }
    }

    const valueCustomator = (data) => {
        return ({
            name: <LBVLabel>{data.name || '-'}</LBVLabel>,
            username: <LBVLabel>{data.username}</LBVLabel>,
            action: <Grid container textAlign={"right"} spacing={2} p={1} justifyContent={"right"}>
                <div style={{display: 'flex'}}>
                    <div style={{ marginRight: 2, minWidth: 70, textAlign: 'center' }}>
                        <Button color="primary" variant="contained" onClick={() => {
                            navigate(`/places/${data._id}`)
                        }}>Edit</Button>
                    </div>
                    <div style={{ marginRight: 2, minWidth: 70, textAlign: 'center' }}>
                        <Button color="warning" variant="contained" onClick={() => {
                            
                        }}>
                            {data.isActive ? "Hide" : "Show"}
                        </Button>
                    </div>
                    <div style={{ marginRight: 2, minWidth: 70, textAlign: 'center' }}>
                        <Button color="error" variant="contained" onClick={() => {
                            
                        }}>Delete</Button>
                    </div>
                </div>
            </Grid>,
        })
    }


    
    return (
        <Grid>
            <Grid mb={1}>
                <Button variant="contained" onClick={() => {
                    navigate('/setting/admins/add-admin')
                }}>Add Admin</Button>
            </Grid>
            <Grid>
                <LBVTable
                    columns={[
                        { label: "Name" },
                        { label: "Username" },
                        { label: "" },
                    ]}
                    loading={loading}
                    valueCustomator={valueCustomator}
                    totalData={0}
                    rows={admins}
                    page={1}
                    // pageChange={(e) => {
                    //     onChangePage({page: e})
                    // }}
                />
            </Grid>
        </Grid>
    )
}

export default Admins